import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faDiscord,
  faTwitter,
  faFacebook,
  faTelegram,
  faMedium,
  faReddit,
  faGitAlt
} from "@fortawesome/free-brands-svg-icons";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useNavigate, Link } from "react-router-dom";

export default function NewFooter() {
  const [width, setWidth] = useState(window.innerWidth);
  const url = "https://publishednft.us6.list-manage.com/subscribe/post?u=07e4e935a467fc4f628f335ba&amp;id=7b096762a9";

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    const submitOnEnter = (event) => {
      if (event.keyCode === 13) {
        submit();
      }
    };
  
    return (
      <div className="newsletter-form">
        <input
          ref={(node) => (email = node)}
          type="email"
          onKeyDown={(e) => submitOnEnter(e)}
          placeholder="Email"
          className= "form-control m-auto"
          required
        />
  
        <button
          className="bg-primary text-white border-radius-10 px-3 pt-1 pb-1 mt-3"
          onClick={submit}
        >
          Subscribe
        </button>    
  
        {status === "sending" && (
          <div className="subs-sending-msg">sending...</div>
        )}
        {status === "error" && (
          <div
            className="subs-error-msg"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="subs-success-msg"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };

  return (
    <footer>
      <div className="container d-flex flex-column flex-lg-row">
        <div className="contact-box me-lg-5">
          <a className="logo mb-2">
            <img src="./img/logo-white.png" alt="" />
          </a>

          <div className="title mb-3">Buy, sell, and trade your eBooks</div>

          {/* <p className="mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut dictum metus
            praesent posuere.
          </p> */}

          <div className="d-flex flex-column flex-lg-row">
            <div className="my-2 me-4"><img src="./img/mail.png" alt="" /></div>
            <div className="my-2">
              <h3>Newsletters</h3>
              <p>Join Published NFT newsletter for latest news and updates!!!</p>
            </div>
          </div>

          <div className="form-send-email">
            <div className="pb-0">Email Address</div>
            {/* <input className="form-control" type="text" placeholder="Enter Email Address" />
            <button className="btn">
              Subscribe 
              <img src="./img/up-right-arrow.png" alt="" />
            </button> */}
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>

        <div id="footer-right">
          <div className="p1-2 d-flex flex-column flex-sm-row" id="nav-item-group">
            <a className="nav-item" target="_blank" href="https://toucans.ecdao.org/p/PublishedNFTDAO">
              DAO
            </a>
            <Link className="nav-item" to="/metaverse">
              Metaverse
            </Link>
            <a className="nav-item" target="_blank" href="https://cast.fyi/#/community/37?tab=about">
              Cast Vote
            </a>
            <a className="nav-item" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdkkt5AU3WwyCdeit0U3vc0HaejojffatR31EbjGDYNSsBb2A/viewform">
              Get Published
            </a>
            <Link className="nav-item" to="/about">
              About
            </Link>
            <Link className="nav-item" to="/faq">
              FAQ
            </Link>
            <a className="nav-item" target="_blank" href="https://discord.gg/K9AtXba9H9">
              Contact Us
            </a>
          </div>

          <div className="pl-2 d-flex flex-column flex-sm-row" id="nav-group">
            <div id="nav-helpful">
              <div className="nav-item-title">
                Helpful Links
              </div>
              <div className="d-flex flex-column">
                <Link className="nav-item" to="/terms">
                  Terms of Service
                </Link>
                <Link className="nav-item" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </div>

            </div>
            <div id="nav-partner-with-us">
              <div className="nav-item-title">
                Compatible Marketplaces
              </div>
              <div id="nav-logo-container" className="ms-2">
                <a className="nav-logo-item me-4" target="_blank" href="https://nft.flowverse.co/marketplace/PublishedNFT?tab=items">
                  <img src="flowverse.png" alt="Flowverse Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://find.xyz/publishednft">
                  <img src="find-logo.png" alt="Find Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://evaluate.xyz/the-published-nft-collection/collections">
                  <img src="evaluate_green_1.png" alt="Evaluate Green Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://shadow.is/A.440776a8205e9f80.PublishedNFT">
                  <img src="shadow_logo.png" alt="Shadow Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://matrixmarket.xyz/collection/mainnet_flow-A.440776a8205e9f80.PublishedNFT">
                  <img src="matrix_market_logo2.png" alt="Matrix Market Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://toucans.ecdao.org/p/PublishedNFTDAO">
                  <img src="toucans-logo.png" alt="Toucans Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://app.increment.fi/swap?in=A.1654653399040a61.FlowToken&out=">
                  <img src="increment_logo_2.png" alt="Increment Logo" />
                </a>
                <a className="nav-logo-item me-4" target="_blank" href="https://www.flowty.io/collection/0x440776a8205e9f80/PublishedNFT/activity/rentals">
                  <img src="flowty_white.png" alt="Flowty Logo" />
                </a>
                <a className="nav-logo-item" target="_blank" href="https://www.flowdiver.io/account/0x440776a8205e9f80">
                  <img src="flowdiver.png" alt="Flowty Logo" />
                </a>
              </div>
            </div>
          </div>

          <div className="pl-2">
            <div className="nav-item-title" href="#">
              Follow Us
            </div>
            <div className="social-icon-container flex-wrap ms-2">
              <a className="social-link me-3" target="_blank" href="https://www.instagram.com/publishednft/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://twitter.com/publishednft">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://discord.com/channels/854792215577755679/854792217428361279">
                <FontAwesomeIcon icon={faDiscord} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://t.me/published_nft">
                <FontAwesomeIcon icon={faTelegram} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://www.facebook.com/Published-NFT-111210031197860">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://publishednft.medium.com/published-nft-limited-pre-sale-nft-release-sale-d23776faa232">
                <FontAwesomeIcon icon={faMedium} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://www.reddit.com/user/PublishedNFT">
                <FontAwesomeIcon icon={faReddit} />
              </a>
              <a className="social-link me-3" target="_blank" href="https://gitbook.publishednft.io/">
                <FontAwesomeIcon icon={faGitAlt} />
              </a>
              {/* <a className="social-link" href=""><FontAwesomeIcon icon={faDiscord} /></a> */}
            </div>
          </div>
          
          <p className="ms-2 meta-data mt-3">
            ©2024 Published NFT™ LLC. All rights reserved. Published NFT is a trademark of Published NFT™ LLC.
          </p>
        </div>
      </div>
    </footer>
  );
}
