import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import "./config";
import Header from './layout/Header';
import Footer from './layout/Footer';
import NewHeader from "./layout/NewHeader";
import NewFooter from "./layout/NewFooter";
import Home from './pages/Home';
import Collection from './pages/Collection';
import Mint from './pages/Mint';
import Description from './pages/Description';
import Roadmap from './pages/Roadmap';
import Marketplace from './pages/Marketplace';
import Metaverse from './pages/Metaverse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';
import Book from "./pages/Book";
import Terms from "./pages/Terms";
import Init from "./pages/Init";
import BookStore from "./pages/BookStore";
import NewBook from "./pages/NewBook";
import HomeOld from "./pages/HomeOld";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/" ? <NewHeader /> : (location.pathname === "/book" ? null : <Header/>) }
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/homeold" element={<HomeOld />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/description/:id" element={<Description />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/metaverse" element={<Metaverse />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/book" element={<Book />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/init" element={<Init />} />
        <Route path="/bookstore" element={<BookStore />} />
        <Route path="/newbook" element={<NewBook />} />
      </Routes>
      
      <ToastContainer />

      {location.pathname === "/" ? <NewFooter /> : <Footer />}
    </>
  );
}

export default App;
