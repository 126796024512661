export const PURCHASESYLLABLES_DAPPER = `
import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import MetadataViews from 0x1d7e57aa55817448
import DapperUtilityCoin from 0xead892083b3e2c6c
import SyllablesNFTStorefrontV2 from 0x440776a8205e9f80
import Syllables from 0x440776a8205e9f80

/// Transaction facilitates the purcahse of listed NFT. It takes the storefront address, listing resource that need to be
/// purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the transaction and if purchase happens then
/// transacted NFT would store in buyer's collection.
///
transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address?) {

    let mainVault: auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault
    let paymentVault: @{FungibleToken.Vault}
    let syllablesReceiver: &{NonFungibleToken.Receiver}
    let storefront: &{SyllablesNFTStorefrontV2.StorefrontPublic}
    let listing: &{SyllablesNFTStorefrontV2.ListingPublic}
    let balanceBeforeTransfer: UFix64
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?

    prepare(dapper: auth(BorrowValue) &Account, buyer: auth(Storage, Capabilities) &Account) {
        self.commissionRecipientCap = nil

        // Initialize the collection if the buyer does not already have one
        if buyer.capabilities.borrow<&Syllables.Collection>(Syllables.CollectionPublicPath) == nil {
            let collection <- Syllables.createEmptyCollection(nftType: Type<@Syllables.NFT>())
            buyer.storage.save(<-collection, to: Syllables.CollectionStoragePath)

            let collectionCap = buyer.capabilities.storage.issue<&Syllables.Collection>(Syllables.CollectionStoragePath)
            buyer.capabilities.publish(collectionCap, at: Syllables.CollectionPublicPath)
        }

        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(0x440776a8205e9f80).capabilities.borrow<&{SyllablesNFTStorefrontV2.StorefrontPublic}>(
                SyllablesNFTStorefrontV2.StorefrontPublicPath
            ) ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let price = self.listing.getDetails().salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainVault = dapper.storage.borrow<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow Syllables vault from acct storage")
        self.balanceBeforeTransfer = self.mainVault.balance
        self.paymentVault <- self.mainVault.withdraw(amount: price)

        // Access the buyer's NFT collection to store the purchased NFT.
        let collectionData = Syllables.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) as! MetadataViews.NFTCollectionData?
            ?? panic("ViewResolver does not resolve NFTCollectionData view")
        self.syllablesReceiver = buyer.capabilities.borrow<&{NonFungibleToken.Receiver}>(collectionData.publicPath)
            ?? panic("Cannot borrow NFT collection receiver from account")

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).capabilities.get<&{FungibleToken.Receiver}>(
                    /public/dapperUtilityCoinReceiver
                )
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DapperUtilityCoin receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    // Check that all utilityCoin was routed back to Dapper
    post {
        self.mainVault.balance == self.balanceBeforeTransfer: "UtilityCoin leakage"
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap
        )
        // Deposit the NFT in the buyer's collection.
        self.syllablesReceiver.deposit(token: <-item)
    }
}
`