import { config } from "@onflow/fcl";
import * as fcl from "@onflow/fcl"
import {send as httpSend} from "@onflow/transport-http"

// Dapper wallet //
config({
  "accessNode.api": "https://access-mainnet.onflow.org",
  "discovery.wallet": "https://accounts.meetdapper.com/fcl/authn-restricted",
  'discovery.wallet.method': "POP/RPC",
  '0xFT': "0xf233dcee88fe0abe",
  '0xFUSD': "0x3c5959b568896393",
  '0xNFT': "0x1d7e57aa55817448",
  '0xStorefront': "0x4eb8a10cb9f87357",
  '0xPublishedNFT': "0x440776a8205e9f80",
  "0xMerchant": "0x03599ddc5b277a6b",
  "0xDapperUtilityCoin": "0xead892083b3e2c6c"
})

// Configure SDK to use HTTP
fcl
  .config()
  .put("accessNode.api", "https://rest-mainnet.onflow.org")
  .put("sdk.transport", httpSend)

fcl.config()
  .put("app.detail.title", "Published NFT")
  .put("app.detail.icon", "https://publishednft.io/logo-desktop.png")
  
// Dapper wallet //